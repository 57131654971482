@import 'theme';
@import 'variables-custom';
@import '../../../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss';

ngx-guided-tour {
  .tour-block {
    box-shadow: none !important;
    border-radius: 3px !important;
    padding: 15px 25px 15px 15px !important;

    .tour-title {
      font-size: 16px !important;
      color: $gray !important;
      padding-bottom: 10px !important;
    }

    .tour-content {
      font-size: 15px !important;
      color: $gray !important;
      padding-bottom: 10px !important;
    }

    .tour-buttons {
      button.skip-button {
        position: absolute !important;
        right: 25px;
        top: 15px;
        text-indent: -9999em;

        width: 20px !important;
        height: 20px !important;
        background-image: url('~src/assets/images/ic_sm_close.svg') !important;
        background-position: center center !important;
        padding: 0 !important;
        margin: 0 !important;
        background-size: 25px !important;
        opacity: 0.6 !important;
      }

      button.next-button {
        background-color: transparent !important;
        color: $red !important;
        font-size: 16px !important;
      }

      button.back-button {
        background-color: transparent !important;
        color: $red !important;
        float: none !important;
        font-weight: normal !important;
        font-size: 16px !important;
      }
    }
  }

  .guided-tour-spotlight-overlay {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.55) !important;
  }
}
