// Autor:     Silvio Siqueira
// E-mail:    silvio.siqueira@accenture.com
// Detalhes:  Arquivo com as regras que serão utilizadas no BLOCO x FASE

.vivereVisivel {
}
.vivereNaoVisivel {
  display: none !important;
}
.vivereEditavel {
}
