@media screen and (max-width: 992px) {
  .push-right {
    .sidebar {
      left: 235px !important;
    }
  }
}
@media screen and (max-width: 480px) {
  .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

img {
  max-width: 100%;
}
