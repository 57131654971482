/**
 * Angular Material styles
 */
@use '@angular/material' as mat;

@import '@angular/material/theming';
@include mat.core();
$primary: #c00;
$muted: #d3d3d3;
$muted-element: #d3d3d3;
$text-color: #222222;
$description-text-color: #767676;
$light-gray: #cccccc;
$border-gray: rgba(25, 25, 25, 0.125);
a {
  color: $primary;
}

/**
 * Colors
 */
$red-arg: #cc0000;
$primary: #c00;
$gray: #767676;
$light-gray: #e7e7e7;
$dark-gray: #999999;
$ultra-light-gray: #f7f7f7;
$product-yellow: #f8ae45;
$product-blue: #40ace2;
$product-purple: #c350ff;
$color-info: #5ab8ca;
$green-success: #739e41;
$yellow-warning: #f2a51a;
$text-danger: #f14c4c;
$scarlet: #f14c4c;
$orange: #f2a51a;
$header-background: #ce0303;
$text-success: #739e41;
$text-danger: #f14c4c;
$button-wht-active: #fde5e5;
$button-active: #990000;
$red-hovered: #b40000;

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary,
.mat-raised-button.mat-primary:focus {
  //border: 1px solid $primary;
  color: $primary;
  background-color: #ffffff;
  outline: none !important;
}

.mat-menu-item {
  color: $primary !important;
}

button.mat-menu-item {
  background-color: white;
}

button.mat-menu-item:hover {
  background-color: $primary;
  color: $primary !important;
}

.mat-stroked-button:not([disabled]) {
  // background: transparent;
  // border: 1px solid $primary;
  // color: #fff;
  border-color: $primary !important;
}

$sytem-red: (
  50: #c00,
  100: #c00,
  200: #c00,
  300: #c00,
  400: #c00,
  500: #c00,
  600: #c00,
  700: #c00,
  800: #c00,
  900: #c00,
  A100: #c00,
  A200: #c00,
  A400: #c00,
  A700: #c00,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white
  )
);
$my-app-primary: mat.define-palette($sytem-red);
$my-app-accent: mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-component-themes($my-app-theme);
.pion-no-padding-dialog {
  height: 100vh;
  padding: 30px;
  .mat-dialog-container {
    position: relative;
    max-height: 100%;
    padding: 30px;
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }
}

.summary-modal-container {
  .mat-dialog-container {
    border-radius: 0;
  }
}

.installments-modal-container,
.simulation-summary-modal-container {
  height: 100vh;
  padding: 0;

  .mat-dialog-container {
    border-radius: 0;
    position: relative;
    max-height: 100%;
    padding: 0;
    overflow: hidden;
  }

  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }
}

// @TODO: Criar um componente
.plan-button {
  width: 48%;
  display: flex;
  background: #fff;
  border-radius: 3px;
  border: 1px solid $gray;
  padding: 16px;
  cursor: pointer;
  height: 88px;
  max-width: 205px;
  min-width: 200px;
  position: relative;

  .button-icon {
    width: 20px;
    margin-right: 5px;
  }

  .text {
    text-align: left;

    p {
      margin-bottom: 0;
      font-size: 14px;
      color: $gray;
      margin-top: 8px;
    }

    .mat-icon {
      font-size: 12px;
      position: absolute;
      bottom: 8px;
      right: 0;
    }
  }
}

.mat-drawer-container {
  background-color: white !important;
}

.link {
  text-decoration: underline !important;
  cursor: pointer;
  &.primary {
    color: $primary !important;
  }
}

.text {
  &.primary {
    color: $primary;
  }
}

/* Fix modal overlay under header component */
.cdk-overlay-container {
  z-index: 99999 !important;
}

/**
 * Simulation global style
 */
.simulation-section {
  display: block;
  margin: 2rem auto;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: start;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }

    span {
      color: $gray;
      font-size: 18px;
      font-weight: bold;

      @media (max-width: 768px) {
        color: $light-gray;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 8px;
      }
    }
  }

  .section-main {
    margin: 1rem 0;
  }
}

/**
 * Mat checkbox global style
 */
.mat-checkbox {
  .mat-checkbox-inner-container {
    height: 20px;
    width: 20px;
  }

  .mat-checkbox-frame {
    border-color: $primary;
    border-radius: 3px;
  }

  .mat-checkbox-background {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;

    svg {
      display: none;
    }
  }
}

/**
 * Fix ngx-guided-tour position and overlay
 */
$tour-zindex: 99999999999;

ngx-guided-tour {
  .tour-step,
  .guided-tour-spotlight-overlay {
    z-index: 9999999999999;
  }
}

.guided-tour-spotlight-overlay.fixPositionStyle {
  left: 28px !important;
}
.guided-tour-spotlight-overlay.fixHeightStyle {
  height: 256px !important;
}
.tour-step.fixPositionStyle {
  left: 56.7px !important;
}

.guided-tour-spotlight-overlay.stepNineHeight {
  height: 58px !important;
}

.tour-step.stepNineHeight {
  margin-top: 19px !important;
}

.guided-tour-spotlight-overlay.stepHeight {
  height: 780px !important;
}

.guided-tour-spotlight-overlay.stepWidth {
  width: 21rem !important;
  margin-left: 3.3rem !important;
}

.extra-info {
  span.mat-select-value-text {
    font-weight: bold !important;
  }

  &.installments-infos {
    .label {
      margin-bottom: 18px;
      color: $gray;
    }

    .value {
      font-weight: bold;
    }
  }
}
