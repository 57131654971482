/* You can add global styles to this file, and also import other style files */

@import './_variables-custom'; // Arquivo com as variáveis customizadas para o projeto
@import '../../_base-styles/bootstrap/root';
@import '../../_base-styles/bootstrap/print';
@import '../../_base-styles/bootstrap/reboot';
@import '../../_base-styles/bootstrap/type';
@import '../../_base-styles/bootstrap/images';
@import '../../_base-styles/bootstrap/code';
@import '../../_base-styles/bootstrap/grid';
@import '../../_base-styles/bootstrap/tables';
@import '../../_base-styles/bootstrap/forms';
@import '../../_base-styles/bootstrap/buttons';
@import '../../_base-styles/bootstrap/transitions';
@import '../../_base-styles/bootstrap/dropdown';
@import '../../_base-styles/bootstrap/button-group';
@import '../../_base-styles/bootstrap/input-group';
@import '../../_base-styles/bootstrap/custom-forms';
@import '../../_base-styles/bootstrap/nav';
@import '../../_base-styles/bootstrap/navbar';
@import '../../_base-styles/bootstrap/card';
@import '../../_base-styles/bootstrap/breadcrumb';
@import '../../_base-styles/bootstrap/pagination';
@import '../../_base-styles/bootstrap/badge';
@import '../../_base-styles/bootstrap/jumbotron';
@import '../../_base-styles/bootstrap/alert';
@import '../../_base-styles/bootstrap/progress';
@import '../../_base-styles/bootstrap/media';
@import '../../_base-styles/bootstrap/list-group';
@import '../../_base-styles/bootstrap/close';
@import '../../_base-styles/bootstrap/modal';
@import '../../_base-styles/bootstrap/tooltip';
@import '../../_base-styles/bootstrap/popover';
@import '../../_base-styles/bootstrap/carousel';
@import '../../_base-styles/bootstrap/utilities';
//@import '~@ng-select/ng-select/themes/default.theme.css';
@import './_components-custom'; // Arquivo com componentes customizados para o projeto
@import '../../_base-styles/spinner';
@import '../../_base-styles/utils';
@import '../../_base-styles/rtl';
@import '../../_base-styles/responsive';
@import '../../_base-styles/bloco-fase';
@import '../../_base-styles/not-found';
/* Angular material */

@import '../../_base-styles/tour';
@import './theme';
