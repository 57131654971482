.error-message {
  margin-top: 0;
  padding-top: 40%;
}

.login-image {
  position: absolute;
  width: 100%;
  height: auto;
  right: 0;
  top: 18%;
}

@media screen and (max-width: 992px) {
  .login-image {
    display: none;
  }

  .error-message {
    padding-top: 40%;
    padding-top: 40vh;
  }
}
