// Autor:     Silvio Siqueira
// E-mail:    silvio.siqueira@accenture.com
// Detalhes:  Arquivo com os componentes customizados, alterando alguns componentes do bootstrap original

//
//  Mixins
//
@mixin transition-all {
  transition: all ease-in-out 150ms;
  -webkit-transition: all ease-in-out 150ms;
}

@mixin forma-botao {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 25px;
  cursor: pointer;

  &:hover {
    outline: none;
  }
}

@mixin color-principal {
  color: $btn-principal-color !important;
  background-color: $btn-principal-bgcolor !important;
}

@mixin botao-principal {
  @include forma-botao;
  @include color-principal;

  &:hover {
    background-color: $btn-principal-hover-bgcolor !important;
    color: $btn-principal-hover-color !important;
  }
}

@mixin botao-secundario {
  @include forma-botao;
  color: $btn-secun-color;
  background-color: $btn-secun-bgcolor !important;
  border: 2px solid $btn-secun-color !important;
  line-height: 36px;

  &:hover {
    background-color: $btn-secun-hover-bgcolor !important;
    color: $btn-secun-hover-color !important;
  }
}

@mixin rounded-btn {
  border-radius: 50px;
  -webkit-border-radius: 50px;
}

@mixin color-login {
  color: $btn-login-color !important;
  background-color: $main-color !important;
}

@mixin botao-login {
  @include forma-botao;
  @include color-login;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-size: 18px;
  padding: 6px 12px;
  line-height: 36px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: $btn-login-hover-bgcolor !important;
    color: #ffffff !important;
  }
}

.btn-login {
  @include botao-login;
}

//
//  Alert
//
.container-alert {
  position: fixed;
  top: 80px;
  right: 0;
  height: 0;
  text-align: center;
  z-index: 999999;
  max-width: 800%;
  left: 50%;
  transform: translateX(-50%);

  ngb-alert {
    // .alert {
    max-width: 800px;
    display: inline-block;
    padding: 0.75rem 2.7rem 0.75rem 1.25rem;

    button {
      cursor: pointer;
      outline: none;
    }
    // }
  }
}

.dados-do-contrato {
  margin-top: -15px;
}

//
//  Alert Sweet
//
.swal2-popup {
  .swal2-styled.swal2-confirm {
    @include botao-principal;
  }
}

.swal2-popup {
  .swal2-styled.swal2-cancel {
    @include botao-secundario;
    color: #424455 !important;
  }
}

.swal2-reverse-confirm {
  .swal2-popup {
    .swal2-styled.swal2-confirm {
      @include botao-secundario;
      color: #424455 !important;
    }
  }

  .swal2-popup {
    .swal2-styled.swal2-cancel {
      @include botao-principal;
    }
  }
}

//
//  Badge
//
.badge-referencia {
  color: $btn-secun-color;
  background-color: $btn-secun-bgcolor !important;
  border: 1px solid $btn-secun-color !important;
}

//
//  Headings
//
h1,
.h1 {
  margin-bottom: 0 !important;
}

//
//  Cards
//
.card-header {
  font-weight: $font-weight-bold;

  .invalid-feedback {
    font-weight: $font-weight-normal;
  }
}

//
//  Button
//
.btn-principal {
  @include botao-principal;
}

.btn-secundario {
  @include botao-secundario;
}

.btn-danger {
  @include forma-botao;
}

.btn-danger:disabled {
  background-color: $btn-link-disabled-color !important;
  border-color: $btn-link-disabled-color !important;
}

.btn-principal-sm {
  line-height: 32px !important;
  font-size: 16px;
}

.btn-principal-xl {
  line-height: 28px !important;
  font-size: 14px;
  padding: 0 14px !important;
}

.btn-principal-group {
  background: #424455;
  color: #fff;
  cursor: pointer;
}

.btn-principal-group:hover {
  background: #3f414d;
}

//
//  DropDowns
//
.dropdown-menu.dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
}

//
//  DatePicker
//
ngb-datepicker.dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

//
//  Forms
//

// label {
//   // font-weight: $font-weight-bold;
//   // font-size: 14px;
// }

//
//  NavBar
//

.text-color-primary {
  color: #3f414d;
  text-decoration: underline !important;
  cursor: pointer;
}

.navbar {
  background-color: $main-color;
  height: 64px;

  .navbar-brand {
    color: $white;
  }

  .nav-item > a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

//
//  Pagination
//
ngb-pagination {
  ul {
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -ms-flex-pack: center !important;
  }
}

//
// Tables
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-head-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

.table.table-no-filter {
  thead {
    th {
      background: $gray-100 !important;
    }
  }
}

.table-sm th {
  padding: 0.3rem !important;
}

//
// Cards
//
.card {
  margin-bottom: 1.5rem;

  .card-body {
    .card-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
}

//
// Collapse
//
ngb-accordion {
  .card + .card {
    margin-top: 16px;
  }
}

ngb-accordion {
  .card {
    .card-header {
      i.fa-plus,
      span.fa-plus {
        float: right;
        display: inline-block;
        margin-top: 5px;
      }
    }
  }
}

ngb-accordion {
  .card {
    .card-header.active {
      i.fa-plus,
      span.fa-plus {
        display: none;
      }
    }
  }
}

ngb-accordion {
  .card {
    .card-header {
      i.fa-minus,
      span.fa-minus {
        display: none;
      }
    }
  }
}

ngb-accordion {
  .card {
    .card-header.active {
      i.fa-minus,
      span.fa-minus {
        float: right;
        display: inline-block;
        margin-top: 5px;
      }
    }
  }
}

//
// Tab Custom
//
ngb-tabset {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  border-radius: 0.25rem;

  ul.nav {
    padding: 0.75rem 1.25rem 0;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .tab-content {
    padding: 1.25rem;
  }
}

//
// Custom
//

.card-table-search {
  .card-header {
    .form-control-simple {
      background: transparent;
      border: none;
      display: inline-block;
      width: 90%;
    }

    .form-control-simple:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

///
/// Modal
///

.modal-header {
  background: white;
  color: black;
  border-bottom: none;

  h4 {
    font-weight: bold !important;
  }

  button {
    cursor: pointer;
    color: $white;
    opacity: 1;
  }

  button:hover {
    color: $white;
  }
}

.modal-footer {
  border-top: none;

  .btn-principal {
    color: $white !important;
  }

  .btn-secundario:hover {
    color: $white !important;
  }
}

.modal-dialog {
  max-width: 900px;

  &.modal-sm {
    max-width: 600px;
  }
}

body.body-no-sidebar {
  .sidebar {
    left: 0;
  }

  .main-container {
    margin-left: 0;
  }
}

.row-cabecalho {
  position: relative;
  background-color: #323545;
  padding: 32px 32px 16px;
  margin: 0 -30px 2.5rem;
  text-transform: uppercase;

  .main-info {
    width: 100%;
  }

  .secundary-info {
    display: none;
  }

  .row-info {
    div {
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 0.8rem;
    color: #999;
    font-weight: 700;
    margin-bottom: 0.3rem;
  }

  span {
    font-size: 1.1rem;
    font-weight: 300;
    color: #fff;
  }

  a.bt-expandir {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 2px 6px 0 #cbd3e8;
    z-index: 1000;

    span {
      font-size: 0.9rem;
      line-height: 2rem;
      color: #4d4d4d;
    }

    &:hover {
      outline: 0;
      text-decoration: none;
    }

    .i-open {
      display: block;
    }

    .i-close {
      display: none;
    }
  }

  .open {
    .secundary-info {
      display: block;
    }

    a.bt-expandir {
      .i-open {
        display: none;
      }

      .i-close {
        display: block;
      }
    }
  }
}

.info-header {
  @include transition-all();
  opacity: 0;
  height: 0;
}

.info-header.active {
  @include transition-all();
  opacity: 1;
  height: auto;
}

.content-boleto {
  width: 100%;
  max-height: 300px;
  overflow: scroll;
}

.row-cabecalho-receptivo {
  margin: 0 -1.9rem 0 !important;
}

.menu-atendimento {
  position: relative;
  background-color: $navbar-bgcolor;
  padding: 24px 32px 0;
  margin: 0 -1.9rem 2.5rem;

  .nav {
    .nav-item {
      .nav-link {
        color: $navbar-textcolor;
        font-weight: $navbar-font-weight;
        font-size: $navbar-font-size;
        padding: 0.5rem 1rem;
        border-radius: 5px 5px 0 0;
        @include transition-all();

        &:hover {
          background: rgba($navbar-bgcolor-hover, 0.1);
        }

        &.link-active {
          border-radius: 5px 5px 0 0;
          background: $navbar-bgcolor-hover;
          color: $navbar-bgcolor;
          font-weight: $navbar-font-weight-active;
        }
      }
    }
  }
}

//
// ng-select
//
/*
ng-select {
  font-weight: normal !important;
  font-size: 0.875rem;
  min-height: 32px !important;
  height: 32px !important;
}
*/
.ng-select {
  font-weight: normal !important;
  font-size: 0.875rem;
  min-height: 32px !important;
  height: 32px !important;
  padding: 0;
  &.form-control {
    border: 0;
  }
  &.ng-select-disabled {
    .ng-select-container {
      background-color: #dddddd;
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) {
      & > .ng-select-container {
        border-color: #99e6e6;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25);
        -moz-box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25);
      }
    }
  }
  &.ng-select-single {
    .ng-select-container {
      height: 31px !important;
      min-height: 0 !important;

      .ng-value-container {
        .ng-input {
          top: 2px;
        }

        .ng-value {
          font-size: 14px;
        }
      }
    }
  }
}

.disableOcorrencia {
  color: #f00 !important;
}

.ng-select.ng-select-single .ng-select-container {
  min-height: 31px !important;
  height: 31px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #f00 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  color: #ccc !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.dropdown-item {
  cursor: pointer;
}

/*.ng-select {
  &.ng-select-focused {
    .ng-select-container {
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 31px !important;
      min-height: 0 !important;

      .ng-value-container {
        .ng-input {
          top: 2px;
        }

        .ng-value {
          font-size: 14px;
        }
      }
    }
  }
}
*/
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      font-size: 14px;
      color: #495057;

      &.ng-option-marked {
        background-color: #f9f9f9;
      }
    }
  }
}

// componente data-saldo

.data-saldo-sm {
  width: 155px;
  margin-right: -18px;
}

.btn-calcular-data-saldo {
  margin-top: 32px;
  margin-left: 17px;
}

//
//  DatePicker aberto para a esquerda * quando existem 7 datas e o tamanho é modificado o calendario era cortadona tela.
//
.datePickerOpenLeft {
  ngb-datepicker.dropdown-menu {
    right: -111px !important;
  }
}

@media screen and (max-width: 768px) {
  .row-cabecalho {
    margin: 0 -25px 2.5rem;
  }
}
