@import 'variables-custom';

.main-container {
  margin-top: 56px;
  margin-left: 235px;
  padding: 30px;
  /*position: relative;
    -ms-overflow-x: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow: hidden;
    */
}

.word-break {
  word-break: break-word;
}

@media screen and (max-width: 992px) {
  .main-container {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    padding: 25px;
  }
}

.fs-12 {
  font-size: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.textRed {
  color: red;
}

.cursor {
  &--pointer {
    cursor: pointer !important;
  }
}

/* input required valid/invalid */
input,
select,
textarea {
  &.validate:not([disabled]):not([readonly]):not([ngbdatepicker]).ng-touched.ng-invalid,
  &[required]:not([disabled]):not([readonly]):not([ngbdatepicker]).ng-touched.ng-invalid {
    @extend .is-invalid;
  }

  & *[required]:not([disabled]):not([readonly]:not([ngbdatepicker])).ng-touched:not(.ng-invalid) {
    @extend .is-valid;
  }
}

/*
REGISTER
*/
.register-form {
  /*&.container {
    width: 100%;
    max-width: 41.5rem !important;
    padding: 0;
  }*/

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .isIconOk {
    color: #739e41;
  }

  .isIconNoOk {
    color: #f14c4c;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .title-register {
    padding: 0;
    margin: 0 0 2rem 0;
    max-width: 35rem;
    font-weight: 300;
    font-size: 2rem !important;
    line-height: 3rem;
    text-align: left;
  }

  .data-fields {
    padding: 1.5rem 0 5rem 0;
    border-bottom: 1px solid #e7e7e7;
  }

  .data--fields {
    padding: 1.5rem 0 5rem 0;
    border: none;
  }

  .data-phases {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    padding: 0.875rem 0;
    color: #cccccc;
  }

  .data-subtitle {
    font-weight: 700;
    font-size: 1.12rem;
    color: #222222;
    line-height: 2.25rem;
    padding-bottom: 1rem;
  }

  .label-form {
    color: #767676;
    font-weight: normal;
    font-size: 1rem;
    display: block;
  }

  .label-col {
    font-weight: 400;
    font-size: 1rem;
    color: #767676;
    line-height: 1.5rem;
  }

  .input-col {
    height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
    color: #222222;
    line-height: 1.2rem;
  }

  .notActive {
    color: #cccccc !important;
  }

  .form-input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  .input-col-sm {
    height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
    color: #222222;
    line-height: 1.2rem;
  }

  .input-col-lg {
    height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
    color: #222222;
    line-height: 1.2rem;
  }

  .input-col-sml {
    height: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
    color: #222222;
    line-height: 1.2rem;
  }

  .textarea {
    height: 13.7rem;
    border: 1px solid #cccccc;
    padding: 1rem !important;
    border-radius: 4px;
    color: #222222;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #222222;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #222222;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #222222;
    }
  }

  .mat-form-field {
    width: 100%;
    padding-bottom: 11.11px !important;
  }

  .mat-form-field-underline {
    background: #cccccc 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
  }

  .mat-form-field-disabled {
    opacity: 0.4;

    input {
      background: none;
    }
  }

  .mat-input-element {
    caret-color: #222222;
    height: 24px;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: #767676 !important;
    font-weight: 400 !important;
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }

  .mat-form-field-ripple {
    background-color: #767676 !important;
  }

  input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input[type='checkbox'] + label {
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    font-weight: normal;
  }

  input[type='checkbox'] + label::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    bottom: 0px;
    border: solid 2px #cc0000;
    vertical-align: bottom;
    border-radius: 4px;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 10px;
    height: 10px;
    border-right: solid 10px #cc0000;
    border-bottom: solid 10px #cc0000;
    border-radius: 2px;
  }

  .mat-form-field {
    width: 100%;
    padding-bottom: 11.11px !important;
  }

  .mat-form-field-underline {
    background: #cccccc 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
  }

  .mat-form-field-disabled {
    opacity: 0.4;

    input {
      background: none;
    }
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: #767676 !important;
    font-weight: 400;
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
  }

  .mat-form-field-ripple {
    background-color: #767676 !important;
  }

  .form-btn1 {
    border: 1px solid #cc0000;
    border-radius: 4px;
    width: 100%;
    height: 3rem;
    background-color: #ffffff;
    color: #cc0000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    cursor: pointer;
    margin-bottom: 5rem;
  }

  .form-btn2 {
    border-radius: 4px;
    width: 100%;
    height: 3rem;
    background-color: #cc0000;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    cursor: pointer;
    margin-bottom: 5rem;
  }

  @include media-breakpoint-down(xs) {
    .title-register {
      font-size: 1.75rem !important;
      line-height: 2.5rem !important;
    }
    .form-btn1 {
      display: none;
    }
    .data-fields {
      padding: 2.875rem 0 2.375rem 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .sidebar {
    display: none;
  }
  .content {
    padding: 2.25rem !important;
  }
  .container {
    max-width: 480px !important;
  }
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: none;
}

.mat-tab-label {
  color: rgba(34, 34, 34, 0.5);
}

.mat-tab-label-active {
  color: #222222;
}

.mat-tab-label,
.mat-tab-link {
  justify-content: flex-start;
  padding: 0;
}

.mat-tab-link:hover {
  color: #222222;
  text-decoration: none;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #cc0000;
  height: 4px;
  border-radius: 2px;
}

.full-screen-modal {
  width: 100vw !important;
  height: 100% !important;
  margin: 0 !important;
  max-width: 100vw !important;

  .mat-dialog-container {
    padding: 0;
    max-width: none;
    border-radius: 0;
  }
}

.bg-dialog-blocked {
  background: #adadad;
}

.danger {
  color: $danger;
}

.h-50 {
  height: 50px !important;
}
